// Core
body {

  .app-header-logo {
    height: $header-height;
    width: $sidebar-width;
    @include media-breakpoint-down(md) {
      display: none;
    }

    display: flex;
    align-items: center;
    background: rgba(255,255,255,.08);
    padding-left: ($spacer);
    padding-right: ($spacer);
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-header-logo-img {
   //  width: 120px;
    width: 100%;
     margin-top: 20px;
    // margin-bottom: 10px;
    height: 100%;
   // height : 80px;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .header-logo-wrapper-btn {
    padding: ($spacer / 3);
    color: $white;
    // background: rgba(255,255,255,.09);

    &:hover {
      // background: rgba(255,255,255,.14);
    }
  }

  .header-logo-wrapper-link {
    text-decoration: none;
  }

  .header-logo-text {
    padding-left: $spacer / 2;
    font-size: $font-size-base * 1.1;
    font-weight: bold;
    width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top : 2px !important;
    padding-bottom: 0px !important;
  }
  .css-1p823my-MuiListItem-root {
    padding-top : 0px !important;
    padding-bottom: 0px !important;
  }
  .css-h4y409-MuiList-root {
    padding-top : 4px !important;
    padding-bottom: 4px !important;
  }
  .MuiListItem-root {
    padding : 0 !important;
  }
  .MuiListItem-padding {
    padding : 0 !important;
  }
}
