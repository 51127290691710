.noShadow {
    box-shadow: none !important;
}

.addBtn {
    padding: 3px 16px !important;
    color: #ffffff !important;
    background-color: #F59300 !important;
}

.cancelBtn {
    padding: 3px 16px !important;
    color: #F59300 !important;
    border: 1px solid #F59300 !important;
    &:hover{
        background-color: #ffffff  !important;
    }
}

.errMsg {
    color: #ff0000;
    font-size: 12px;
    text-align: left;
}

.paginationLabel {
    .MuiTablePagination-selectLabel {
        padding-top: 15px !important;
    }

    .MuiTablePagination-displayedRows {
        padding-top: 15px !important;

    }
}